<template>
  <div :class="`investor-profile ${showAddySignModal || showHelloSign ? 'one-page' : ''}`">
    <WelcomeMessage/>
    <div class="page-header sticky-header">
      <MenuAndLogo :showReferral="true" referralIconBackgroundColor="#fff"></MenuAndLogo>
      <div class="header-title has-text-centered has-text-weight-semibold">Your profile</div>
    </div>
    <div class="main-body">
      <div class="profile-card">
        <div class="is-flex icon-and-name">
          <img src="@assets/images/profile/avatar.svg" alt="avatar" class="avatar">
          <div class="name-and-handle is-flex is-flex-direction-column is-justify-content-center">
            <div class="name has-text-weight-semibold">{{firstName}} {{lastName}}</div>
            <div>{{addyHandle}}</div>
          </div>
        </div>
        <div class="phone" v-show="showMoreDetails" data-testid="phone">
          <div class="section-title has-text-weight-semibold">Phone number</div>
          <div>{{mobileNumber | formatPhoneNumber}}</div>
        </div>
        <div class="email" v-show="showMoreDetails" data-testid="email">
          <div class="section-title has-text-weight-semibold">Email</div>
          <div>{{email}}</div>
        </div>
        <div class="membership-status has-text-centered" v-if="membershipExpiresAt || showMoreDetails">
          <div class="pb-1" v-show="showMoreDetails">Member since {{investorCreatedAt}} 🎉</div>
          <div v-show="membershipExpiresAt">Membership expires on {{ membershipExpiryDate }}</div>
        </div>
        <div class="toggle is-flex is-justify-content-center" @click="showMoreDetails = !showMoreDetails" data-testid="info-toggle">
          <span class="is-flex is-align-items-center is-clickable">
            {{showMoreDetails ? 'Hide' : 'Show'}}
            <img :src="`https://addy-public.s3.us-west-2.amazonaws.com/arrow-${showMoreDetails ? 'up' : 'down'}.svg`" alt="icon">
          </span>
        </div>
      </div>
      <div class="verify-account is-flex is-justify-content-space-between is-align-items-center" @click="verifyAccount" data-testid="verify-account">
        <div class="left is-flex is-align-items-center">
          <div class="icon-background is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/images/profile/verify-account.svg" alt="icon">
          </div>
          <div class="item" data-testid="verify-account-text">{{governmentIdStatus === 'verified' ? 'Account verified' : 'Verify your account'}}</div>
        </div>
        <img v-if="governmentIdStatus === 'verified'" src="@assets/icons/check-green.svg" alt="verified" class="account-icon mr-4">
        <template v-else-if="governmentIdStatus === 'pending'">
          <b-tooltip
            type="is-dark"
            :triggers="['click', 'hover']"
            :auto-close="true"
            position="is-left"
            multilined
          >
            <template v-slot:content>This verification usually takes about a minute. Please check back later.</template>
            <img src="@assets/icons/pending-blue.svg" alt="pending" class="account-icon mr-4 is-clickable tooltip-info">
          </b-tooltip>
        </template>
        <img v-else-if="governmentIdStatus === 'failed'" src="@assets/icons/exclamation.svg" alt="failed" class="account-icon failed mr-2">
        <img v-else src="@assets/icons/arrow-right.svg" alt="arrow" class="account-icon mr-4">
      </div>
      <div class="agreement is-flex is-justify-content-space-between is-align-items-center" @click="viewOrSignAgreement" data-testid="conf-agreement">
        <div class="left is-flex is-align-items-center">
          <div class="icon-background is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/images/profile/confidentiality-agreement.svg" alt="icon">
          </div>
          <div class="item">Confidentiality agreement</div>
        </div>
        <button class="button is-loading mr-2" v-if="isFetchingAddySignUrl || showHelloSign || isFetchingAgreement"></button>
        <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4" v-else>
      </div>
      <div
        class="discord is-flex is-justify-content-space-between is-align-items-center"
        @click="connectToDiscord"
        v-if="showDiscordConnectionButton && discordConnectionLink"
        data-testid="discord-link"
      >
        <div class="left is-flex is-align-items-center">
          <div class="icon-background is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/images/profile/discord-linking.svg" alt="icon">
          </div>
          <div class="item">Link Discord account</div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
      </div>
      <template v-if="taxDocumentGenerationFeatureEnabled && isCanadian">
        <div
          class="tax-docs is-flex is-justify-content-space-between is-align-items-center"
          data-testid="tax-docs"
          v-if="!hasTaxDocs || !t5GenerationEnabled"
          @click="toSinInput"
        >
          <div class="left is-flex is-align-items-center">
            <div class="icon-background is-flex is-justify-content-center is-align-items-center">
              <img src="@assets/images/profile/tax-documents.svg" alt="icon">
            </div>
            <div class="item">Submit your SIN</div>
          </div>
          <img v-if="sinSubmissionCompleted" src="@assets/icons/check-green.svg" alt="verified" class="mr-4">
          <img v-else src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
      </template>
      <div
        v-if="showSuitabilityQuestionnaire"
        class="questionnaire is-flex is-justify-content-space-between is-align-items-center"
        @click="updateSuitabilityQuestionnaire"
      >
        <div class="left is-flex is-align-items-center">
          <div class="icon-background is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/images/profile/suitability-questionnaire.svg" alt="icon" class="ml-1">
          </div>
          <div class="item">Suitability questionnaire</div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
      </div>
      <template v-if="taxDocumentGenerationFeatureEnabled && isCanadian">
        <div
          class="tax-docs is-flex is-justify-content-space-between is-align-items-center"
          data-testid="tax-docs"
          v-if="hasTaxDocs && t5GenerationEnabled"
          @click="toTaxDocs"
        >
          <div class="left is-flex is-align-items-center">
            <div class="icon-background is-flex is-justify-content-center is-align-items-center">
              <img src="@assets/images/profile/tax-documents.svg" alt="icon">
            </div>
            <div class="item">View Tax Documents</div>
          </div>
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
      </template>
      <div class="support has-text-weight-semibold">
        <div class="section-title has-text-weight-semibold has-text-blue">SUPPORT</div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center item is-clickable"
          v-for="(item, key) in support"
          :key="key"
          :data-testid="`support-${key}`"
          @click="toWordpress(item.link)"
        >
          <span>{{item.text}}</span>
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
      </div>
      <div class="account has-text-weight-semibold">
        <div class="section-title has-text-weight-semibold has-text-blue">ACCOUNT</div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center item is-clickable"
          data-testid="reset-pwd"
          @click="resetPwd"
        >
          <span>Reset password</span>
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center item logout is-clickable"
          data-testid="logout"
          @click="logout"
        >
          <span class="has-text-primary">Log out</span>
          <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
        </div>
      </div>
    </div>
    <ConfirmPasswordModal
      :showModal="showPasswordConfirmModal"
      @closeModal="showPasswordConfirmModal = false"
      @passwordConfirmed="passwordConfirmed"
    ></ConfirmPasswordModal>
    <ConfirmModal
      :title="confirmModalTitle"
      :text="confirmModalText"
      :showModal="showModal"
      @closeModal="showModal = false"
    ></ConfirmModal>
    <ConfirmModal
      title="Generating a copy for download"
      text="Thank you for signing the confidentiality agreement. It will take a moment for us to generate a copy for you. Please come back later 🙏."
      :showModal="showAgreementWaitingModal"
      @closeModal="showAgreementWaitingModal = false"
    ></ConfirmModal>
    <IdVerificationModal
      :showModal="showVerificationModal"
      :closeModal="() => { showVerificationModal = false }"
      :governmentIdStatus="governmentIdStatus"
      :investorOnVeriffWatchlist="investorOnVeriffWatchlist"
      :useVeriff="useVeriff"
    >
    </IdVerificationModal>
    <div class="addySign-container" v-if="showAddySignModal">
      <button class="modal-close is-large" @click="showAddySignModal = false"></button>
      <iframe :src="addySignUrl" title="Confidentiality Agreement" id="addySign-iframe"></iframe>
    </div>
    <Flash
      title="Congrats!"
      content="You have successfully linked to Discord."
      :show="showSuccessFlashForDiscord"
      :hideFlash="hideFlashAction"
    ></Flash>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import ConfirmPasswordModal from '@components/modal/confirm-password-modal.vue'
import IdVerificationModal from '@components/modal/id-verification-modal.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import axios from '@lib/axios/middleware'
import signConfidentialityAgreement from '@utils/mixins/sign-confidentiality-agreement.js'
import { logOutAndCleanSession } from '@utils/common-methods/auth'
import { buildDiscordConnectUrl, embeddedInIos, isAmericanUser } from '@utils/common-methods/common'
import { toggleIntercom } from '@utils/common-methods/intercom'
import { doAccountVerification } from '@utils/common-methods/profileHelper'
import { getInvestorData } from '@api/signup'
import { getProfileDetails, registerRoleOnDiscord } from '@api/profile'
import { getFeatureToggle } from '@api/common'
import { resetPassword } from '@api/auth'
import { DateTime } from 'luxon'

export default {
  mixins: [signConfidentialityAgreement],
  data() {
    return {
      firstName: '',
      lastName: '',
      addyHandle: '',
      mobileNumber: '',
      email: '',
      showMoreDetails: false,
      showModal: false,
      confirmModalTitle: '',
      confirmModalText: '',
      governmentIdStatus: '',
      showVerificationModal: false,
      hasAcceptedConfidentiality: false,
      confidentialityAgreementUrl: '',
      isFetchingAgreement: false,
      showAgreementWaitingModal: false,
      showDiscordConnectionButton: false,
      useVeriff: true,
      showSuitabilityQuestionnaire: false,
      showSuccessFlashForDiscord: false,
      showPasswordConfirmModal: false,
      hasTaxDocs: false,
      sinIsPresent: false,
      sinOptoutDate: null,
      investorOnVeriffWatchlist: false,
      membershipExpiresAt: null,
      accountCreatedAt: null,
    }
  },
  computed: {
    investorCreatedAt() {
      if (!this.accountCreatedAt) return ''
      return DateTime.fromISO(this.accountCreatedAt).toFormat('MMM d, yyyy')
    },
    taxDocumentGenerationFeatureEnabled() {
      return this.$store.state.flipperToggles.taxDocumentGeneration
    },
    t5GenerationEnabled() {
      return this.$store.state.flipperToggles.t5Generation
    },
    sinSubmissionCompleted() {
      return this.sinIsPresent || this.sinOptoutDate
    },
    discordConnectionLink() {
      return buildDiscordConnectUrl()
    },
    isCanadian() {
      return !isAmericanUser()
    },
    support() {
      const domain = this.isCanadian ? 'ca' : 'com'
      return [
        {
          text: 'Terms of use',
          link: `https://www.addyinvest.${domain}/terms-of-use/`,
        },
        {
          text: 'FAQ',
          link: 'https://support.addyinvest.com/',
        },
        {
          text: 'Are you an Eligible or Accredited Investor?',
          link: `https://www.addyinvest.${domain}/accreditedinvestors/`,
        },
      ]
    },
    membershipExpiryDate() {
      if (!this.membershipExpiresAt) return ''
      return DateTime.fromISO(this.membershipExpiresAt).toFormat('MMM d, yyyy')
    },
  },
  filters: {
    formatPhoneNumber(value) {
      const stripped = value.replaceAll(/\D/g, '')
      if (stripped.length === 10) return `(${stripped.substring(0, 3)}) ${stripped.substring(3, 6)}-${stripped.substring(6)}`
      else return `${stripped.charAt(0)}(${stripped.substring(1, 4)}) ${stripped.substring(4, 7)}-${stripped.substring(7)}`
    },
  },
  components: {
    WelcomeMessage,
    MenuAndLogo,
    ConfirmModal,
    ConfirmPasswordModal,
    Flash,
    IdVerificationModal,
  },
  created() {
    this.getData()
    this.handleDiscordRedirection()
  },
  mounted() {
    toggleIntercom(false)
  },
  watch: {
    '$route.query.agreement'(value) {
      if (value === 'signed') {
        this.getData()
        this.showAddySignModal = false
      }
    },
  },
  methods: {
    getData() {
      axios.all([
        getInvestorData(),
        getProfileDetails(),
        getFeatureToggle('discord_account_linking'),
        getFeatureToggle('veriff_integration'),
        getFeatureToggle('suitability_questionnaire'),
      ]).then((result) => {
        const { firstName, lastName, addyHandle, mobileNumber, email, createdAt } = result[0]
        this.firstName = firstName
        this.lastName = lastName
        this.addyHandle = addyHandle
        this.mobileNumber = mobileNumber
        this.email = email
        this.accountCreatedAt = createdAt

        if (result[2].success) {
          this.showDiscordConnectionButton = result[2].data.enabled
        }

        if (result[3].success) {
          this.useVeriff = result[3].data.enabled
        }

        if (result[4].success) {
          this.showSuitabilityQuestionnaire = result[4].data.enabled
        }

        if (!result[1].success) return
        this.governmentIdStatus = result[1].data.governmentIdStatus
        this.investorOnVeriffWatchlist = result[1].data.investorOnVeriffWatchlist
        this.confidentialityAgreementUrl = result[1].data.confidentialityAgreementUrl
        this.hasAcceptedConfidentiality = result[1].data.hasAcceptedConfidentiality
        this.membershipExpiresAt = result[1].data.membershipExpiresAt

        this.hasTaxDocs = result[1].data.taxDocsAvailable
        this.sinIsPresent = result[1].data.hasUploadedSin
        this.sinOptoutDate = result[1].data.sinOptoutDate

        this.handleAutoSigningFromPropBuy()
      })
    },
    handleAutoSigningFromPropBuy() {
      const { returnAfterSign } = this.$route.query
      if (returnAfterSign) this.attemptToSignAgreement(decodeURIComponent(returnAfterSign))
    },
    handleDiscordRedirection() {
      const { discord, flash } = this.$route.query
      if (discord !== 'connected') return
      if (flash === 'success') this.showSuccessFlash()
      else this.registerRole()
    },
    toSinInput() {
      if (this.sinSubmissionCompleted) return
      this.$router.push('/investorProfile/sinInput')
    },
    toTaxDocs() {
      if (this.sinSubmissionCompleted) this.showPasswordConfirmModal = true
      else this.toSinInput()
    },
    passwordConfirmed(taxDocs) {
      localStorage.setItem('taxDocs', JSON.stringify(taxDocs))
      this.$router.push('/investorProfile/taxDocs')
    },
    registerRole() {
      const url = new URL(window.location.href)
      const code = url.searchParams.get('code')

      if (embeddedInIos()) return window.webkit.messageHandlers.discordDidRedirectSuccessfully.postMessage(code)

      registerRoleOnDiscord(code).then((result) => {
        if (!result.success) return
        window.location.href = '/investorProfile?flash=success&discord=connected'
      })
    },
    showSuccessFlash() {
      this.showSuccessFlashForDiscord = true
      setTimeout(() => {
        this.showSuccessFlashForDiscord = false
      }, 5000)
    },
    hideFlashAction() {
      this.showSuccessFlashForDiscord = false
    },
    toWordpress(link) {
      window.open(link)
    },
    resetPwd() {
      resetPassword({
        email: this.email,
      }).then((data) => {
        if (data.success) {
          this.confirmModalTitle = 'Password Reset'
          this.confirmModalText = 'Password Reset! Please check your email for instructions.'
        } else {
          this.confirmModalTitle = 'Reset Password Failed'
          this.confirmModalText = data.message
        }
        this.showModal = true
      })
    },
    logout() {
      logOutAndCleanSession().then(() => {
        this.$router.push('/login')
      })
    },
    verifyAccount() {
      if (this.governmentIdStatus === 'verified' || this.governmentIdStatus === 'pending') return
      if (this.investorOnVeriffWatchlist) this.showVerificationModal = true
      else doAccountVerification(this.useVeriff, this)
    },
    viewOrSignAgreement() {
      if (this.isFetchingAgreement) return

      this.isFetchingAgreement = true
      getProfileDetails().then((result) => {
        this.isFetchingAgreement = false

        if (!result.success) return

        const { hasAcceptedConfidentiality, confidentialityAgreementUrl } = result.data
        this.hasAcceptedConfidentiality = hasAcceptedConfidentiality
        this.confidentialityAgreementUrl = confidentialityAgreementUrl

        if (hasAcceptedConfidentiality) this.viewAgreement()
        else this.attemptToSignAgreement()
      })
    },
    viewAgreement() {
      if (this.confidentialityAgreementUrl) location.assign(this.confidentialityAgreementUrl)
      else this.showAgreementWaitingModal = true
    },
    attemptToSignAgreement(returnUrl = undefined) {
      if (this.governmentIdStatus === 'verified') this.signAgreement(returnUrl)
      else this.showVerificationModal = true
    },
    async getUpdatedMemberProfile(callback) {
      await this.getData()
      callback()
    },
    connectToDiscord() {
      window.open(this.discordConnectionLink)
    },
    updateSuitabilityQuestionnaire() {
      this.$router.push('/investorProfile/suitabilityQuestionnaire')
    },
  },
}
</script>
<style lang="scss" scoped>
$hover-background: #f6f5fc86;

.investor-profile {
  padding-bottom: 80px;
  &.one-page {
    height: 100vh;
    overflow: hidden;
  }
  .page-header {
    height: 111px;
    background-color: #F0EEF8;
    font-size: 28px;
    .header-title {
      margin-top: -8px;
    }
  }
  .main-body {
    padding: 19px 25px 0;
    .profile-card {
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      padding: 0 25px 10px;
      .icon-and-name {
        padding-top: 28px;
        padding-bottom: 20px;
        .avatar {
          width: 71px;
        }
        .name-and-handle {
          padding-left: 16px;
          font-size: 16px;
          line-height: 19px;
        }
      }
      .phone,
      .email {
        font-size: 16px;
        line-height: 19px;
      }
      .phone {
        margin-bottom: 10px;
      }
      .email {
        padding-bottom: 24px;
      }
      .membership-status {
        padding-top: 4px;
        padding-bottom: 18px;
        font-size: 16px;
        line-height: 19px;
      }
      .toggle {
        font-size: 14px;
      }
    }
    .verify-account,
    .agreement,
    .discord,
    .tax-docs,
    .questionnaire {
      &:hover {
        background-color: $hover-background;
        cursor: pointer;
      }
      .icon-background {
        width: 40px;
        height: 40px;
        background-color: #F0EEF8;
        border-radius: 50%;
        margin-right: 14px;
        img {
          width: 22px;
        }
      }
      .item {
        font-size: 20px;
      }
    }
    .verify-account {
      margin-top: 20px;
      padding-top: 22px;
      padding-bottom: 22px;
      border-bottom: 1px solid #ECECEC;
      .account-icon {
        &.failed {
          width: 28px;
        }
      }
    }
    .agreement {
      padding: 22px 0;
      .button {
        border-color: transparent;
        background: transparent;
      }
    }
    .discord {
      padding: 22px 0;
      border-top: 1px solid #ECECEC;
      .icon-background img {
        width: 19px;
      }
    }
    .tax-docs {
      padding: 22px 0;
      border-top: 1px solid #ECECEC;
    }
    .questionnaire {
      padding: 22px 0;
      border-top: 1px solid #ECECEC;
      .icon-background img {
        width: 19px;
      }
    }
    .support,
    .account {
      padding-top: 42px;
      .section-title {
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ECECEC;
      }
      .item {
        &:hover {
          background-color: $hover-background;
        }
        padding: 20px 0;
        border-bottom: 1px solid #ECECEC;
        font-size: 16px;
        font-weight: normal;
        &.logout {
          border-bottom: none;
        }
      }
    }
    .support {
      padding-top: 20px;
    }
  }
  .addySign-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .modal-close {
      top: 0.5rem;
      left: 6px;
      &::before,
      &::after {
        background-color: #4A26AA;
      }
    }
    #addySign-iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
