import { fetchConfidentialityUrl, getHelloSignAttributes, updateSignStatus } from '@api/profile'
import { getFeatureToggle } from '@api/common'
import { getEnvironment } from '@utils/common-methods/getEnvironment'
import { toggleIntercom } from '@utils/common-methods/intercom'
import HelloSign from 'hellosign-embedded'

const signConfidentialityAgreement = {
  data() {
    return {
      useAddySign: true,
      addySignUrl: '',
      showAddySignModal: false,
      isFetchingAddySignUrl: false,
      showHelloSign: false,
    }
  },
  watch: {
    showAddySignModal(value) {
      toggleIntercom(value)
    },
  },
  created() {
    this.getToggle()
  },
  methods: {
    getToggle() {
      getFeatureToggle('addy_sign_confidentiality_agreement').then((result) => {
        if (!result.success) return
        this.useAddySign = result.data.enabled
      })
    },
    openAddySign(nextStepUrl, callback) {
      if (this.isFetchingAddySignUrl) return
      this.isFetchingAddySignUrl = true
      fetchConfidentialityUrl(encodeURIComponent(nextStepUrl)).then((result) => {
        this.isFetchingAddySignUrl = false
        if (!result.success) return
        callback(result.data.addySignUrl)
      })
    },
    openHelloSign(onSuccess) {
      if (this.showHelloSign) return
      this.showHelloSign = true

      getHelloSignAttributes().then((result) => {
        if (!result.success) return
        toggleIntercom(true)

        const { hellosignClientId, signUrl } = result.data

        const isProduction = getEnvironment() === 'production'

        const client = new HelloSign({ clientId: hellosignClientId })
        client.open(signUrl, {
          skipDomainVerification: !isProduction,
          allowCancel: true,
        })
        client.on('sign', () => {
          updateSignStatus().then((res) => {
            if (!res.success) return
            this.showHelloSign = false
            toggleIntercom(false)
            onSuccess(client)
          })
        })
        client.on('cancel', () => {
          this.showHelloSign = false
          toggleIntercom(false)
        })
      })
    },
    signAgreement(returnUrl = undefined) {
      if (this.useAddySign) {
        this.openAddySign(
          returnUrl !== undefined ? returnUrl : `${window.location.origin}/investorProfile?agreement=signed`,
          (addySignUrl) => {
            this.addySignUrl = addySignUrl
            this.toggleAddySignModal(true)
          }
        )
      } else {
        this.openHelloSign((client) => {
          client.close()
          this.$router.push(returnUrl !== undefined ? returnUrl : '/investorProfile')
        })
      }
    },
    toggleAddySignModal(status) {
      this.showAddySignModal = status
      this.$emit('updateAddySignModalStatus', status)
    },
  }
}
export default signConfidentialityAgreement
